import { DropDownLabelValue } from '@/app/ui/components/DropdownSelect/interface'
import { GroupOption } from '@/app/ui/components/OptionBox/interfaces'
export enum StatusType {
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
  CANCELLED = 'CANCELLED',
  PUBLISHED = 'PUBLISHED',
}

export enum UserType {
  SELECTED = 'SELECTED_USERS',
  LOGIN = 'ALL_LOGIN_USERS',
  SPECIFIC_OS = 'SPECIFIC_OS',
  SPECIFIC_ORIGIN = 'SPECIFIC_ORIGIN',
  MEMBERSHIP_LEVEL = 'MEMBERSHIP_LEVEL',
}

export enum TriggerType {
  SCHEDULE = 'ON_SCHEDULE',
  INVOICE_PAID = 'INVOICE_PAID',
  REGISTRATION = 'REGISTRATION',
  MEMBERSHIP_LEVEL_UP = 'MEMBERSHIP_LEVEL_UP',
}

export enum PlatformOsType {
  IOS = 'IOS',
  ANDROID = 'ANDROID',
}

export enum MeasurementType {
  GREATER_THAN = 'GREATER_THAN',
  LOWER_THAN = 'LOWER_THAN',
  EQUAL = 'EQUAL',
}

export enum TermConditionType {
  REDEEM_TO_POINT = 'REDEEM_TO_POINT',
  REDEEM_PARCEL_POINT = 'REDEEM_TO_LOYALTY_POINT',
  DISCOUNT_BY_AMOUNT = 'DISCOUNT_BY_AMOUNT',
  DISCOUNT_BY_PERCENTAGE = 'DISCOUNT_BY_PERCENTAGE',
  CASHBACK = 'CASHBACK',
}

export enum MembershipLevelType {
  BASIC = 'Basic',
  REGULAR = 'Regular',
  PREMIUM = 'Premium',
}

export enum LevelUpType {
  BASIC_REGULAR = 'Basic-Regular',
  REGULAR_PREMIUM = 'Regular-Premium',
}

export enum PurposeType {
  PAYMENT = 'PAYMENT',
  REDEEM_POINT = 'REDEEM_TO_POINT',
  REDEEM_PARCEL_POINT = 'REDEEM_TO_LOYALTY_POINT',
}

export enum ShipmentType {
  PICKUP = 'PICKUP',
  DROPOFF = 'DROPOFF',
  PICKUP_FAVORITE = 'PICKUP_FAVORITE',
  DROPOFF_FAVORITE = 'DROPOFF_FAVORITE'
}

export const TARGET_USERS: DropDownLabelValue<UserType>[] = [
  { label: 'All Login Users', value: UserType.LOGIN },
  { label: 'Selected Users', value: UserType.SELECTED },
  {
    label: 'Specific OS',
    value: UserType.SPECIFIC_OS,
    $isDisabled: true,
  },
  {
    label: 'Specific Origin',
    value: UserType.SPECIFIC_ORIGIN,
    $isDisabled: true,
  },
  {
    label: 'Membership Level',
    value: UserType.MEMBERSHIP_LEVEL,
    $isDisabled: true,
  },
]

export const PLATFORM_OS_OPTIONS: DropDownLabelValue<PlatformOsType>[] = [
  {
    label: 'iOS',
    value: PlatformOsType.IOS,
  },
  {
    label: 'Android',
    value: PlatformOsType.ANDROID,
  },
]

export const TERM_COND_OPTIONS: DropDownLabelValue<TermConditionType>[] = [
  {
    label: 'Redeem To Point',
    value: TermConditionType.REDEEM_TO_POINT,
  },
  {
    label: 'Redeem To Parcel Point',
    value: TermConditionType.REDEEM_PARCEL_POINT,
  },
  {
    label: 'Discount By Amount',
    value: TermConditionType.DISCOUNT_BY_AMOUNT,
  },
  {
    label: 'Discount By Percentage',
    value: TermConditionType.DISCOUNT_BY_PERCENTAGE,
  },
  {
    label: 'Cashback',
    value: TermConditionType.CASHBACK,
  },
]

export const MEASUREMENT_OPTIONS: DropDownLabelValue<MeasurementType>[] = [
  {
    label: 'Greater Than',
    value: MeasurementType.GREATER_THAN,
  },
  {
    label: 'Lower Than',
    value: MeasurementType.LOWER_THAN,
  },
  {
    label: 'Equal',
    value: MeasurementType.EQUAL,
  },
]

export const MEMBERSHIP_OPTIONS: DropDownLabelValue<MembershipLevelType>[] = [
  {
    label: 'Basic',
    value: MembershipLevelType.BASIC,
  },
  {
    label: 'Reguler',
    value: MembershipLevelType.REGULAR,
  },
  {
    label: 'Premium',
    value: MembershipLevelType.PREMIUM,
  },
]

export const LEVEL_UP_OPTIONS: DropDownLabelValue<LevelUpType>[] = [
  {
    label: 'Basic To Reguler',
    value: LevelUpType.BASIC_REGULAR,
  },
  {
    label: 'Reguler To Premium',
    value: LevelUpType.REGULAR_PREMIUM,
  },
]

export const PURPOSE_OPTIONS: DropDownLabelValue<PurposeType>[] = [
  {
    label: 'Payment',
    value: PurposeType.PAYMENT,
  },
  {
    label: 'Redeem To Point',
    value: PurposeType.REDEEM_POINT,
  },
  {
    label: 'Redeem To Parcel Poin',
    value: PurposeType.REDEEM_PARCEL_POINT,
  },
]

export const POINT_PURPOSES: DropDownLabelValue<string>[] = [
  {
    label: 'Pickup Reguler',
    value: ShipmentType.PICKUP,
  },
  {
    label: 'Drop paket Reguler',
    value: ShipmentType.DROPOFF,
  },
  {
    label: 'Pickup Favorit',
    value: ShipmentType.PICKUP_FAVORITE,
  },
  {
    label: 'Drop paket Favorit',
    value: ShipmentType.DROPOFF_FAVORITE,
  },
]

export const PRODUCT_TYPE: GroupOption<string, string>[] = [
  {
    label: 'Same Day',
    value: 'SAMEDAY',
    disabled: true,
    message: 'Same Day currently available for Pickup Reguler only',
    showMessage: true
  }
]
