import { container } from 'tsyringe'
import Vue from 'vue'
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'
import dayjs from 'dayjs'
import store from '@/app/ui/store'
import { Pagination } from '@/domain/entities/Pagination'
import {
  PROGRAMMATIC_VOUCHER_PAGINATION,
  PROGRAMMATIC_VOUCHER_HISTORY_PAGINATION,
} from '@/app/infrastructures/misc/Constants'
import {
  EventBus,
  EventBusConstants,
  LocalStorage,
  Utils,
} from '@/app/infrastructures/misc'
import {
  ProgrammaticVoucher,
  ProgrammaticVouchers,
  ProgrammaticVoucherHistory,
  ProgrammaticVoucherHistories,
  VoucherDistribution,
  VoucherDistributions,
} from '@/domain/entities/ProgrammaticVoucher'
import { ProgrammaticVoucherPresenter } from '@/app/ui/presenters/ProgrammaticVoucherPresenter'
import {
  CreateProgrammaticVoucherRequest,
  DeleteProgrammaticVoucherRequest,
  UpdateProgrammaticVoucherRequest,
  UploadProgrammaticVoucherRequest,
} from '@/data/payload/api/ProgrammaticVoucherRequest'
import { Trigger } from '@/domain/entities/Program'
import { IParameter } from '@/data/infrastructures/misc/interfaces/progamaticVoucher'
import {
  FormProgrammaticVoucherCreate,
  FormProgrammaticVoucherEdit,
} from '@/data/infrastructures/misc/interfaces/progamaticVoucher'

export interface ProgrammaticVoucherState {
  isLoading: boolean
  isDownloading: boolean
  isUploading: boolean
  isLoadingRoute: boolean
  isLoadingTrigger: boolean
  voucherData: ProgrammaticVoucher[]
  voucherDetail: ProgrammaticVoucher
  paginationData: Pagination
  triggerData: Record<string, string>[]
  historyData: ProgrammaticVoucherHistory[]
  paginationHistory: Pagination
  distributionData: VoucherDistribution[]
  paginationDistribution: Pagination
}

@Module({
  namespaced: true,
  store,
  name: 'programmatic-voucher',
  dynamic: true,
})
class ProgrammaticVoucherController extends VuexModule
  implements ProgrammaticVoucherState {
  private presenter: ProgrammaticVoucherPresenter = container.resolve(
    ProgrammaticVoucherPresenter
  )
  public isLoading = false
  public isDownloading = false
  public isUploading = false
  public isLoadingRoute = false
  public isLoadingTrigger = false
  public voucherData = [new ProgrammaticVoucher()]
  public voucherDetail = new ProgrammaticVoucher()
  public paginationData = new Pagination(1, PROGRAMMATIC_VOUCHER_PAGINATION, 0)
  public historyData = [new ProgrammaticVoucherHistory()]
  public paginationHistory = new Pagination(
    1,
    PROGRAMMATIC_VOUCHER_HISTORY_PAGINATION,
    0
  )
  public distributionData = [new VoucherDistribution()]
  public paginationDistribution = new Pagination(
    1,
    PROGRAMMATIC_VOUCHER_HISTORY_PAGINATION,
    0
  )
  public triggerData = [{ label: '', value: '' }]
  public isSuccessCancelVoucher = false
  public isSuccessCreate = false
  public isSuccessUpdate = false

  @Action({ rawError: true })
  public getProgrammaticVoucherList(params: Record<string, unknown>) {
    this.setLoading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getAll(formattedParams)
      .then(res => {
        this.setProgrammaticVoucherData(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Programmatic Voucher Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getProgrammaticVoucherDetail(publicVoucherId: string) {
    this.setLoading(true)

    this.presenter
      .get(publicVoucherId)
      .then(res => {
        this.setProgrammaticVoucherDetail(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Programmatic Voucher Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public createProgrammaticVoucher(form: FormProgrammaticVoucherCreate) {
    this.setLoading(true)
    const adminEmail = LocalStorage.getLocalStorage(
      LocalStorage.LOGIN_IDENTITY,
      true
    )

    const payload = new CreateProgrammaticVoucherRequest(
      form.voucherName,
      form.voucherTarget.value,
      undefined,
      undefined,
      undefined,
      form.os.value,
      form.osValidation.value,
      form.osValidationVersion,
      form.routeIds.map(item => item.value),
      form.membershipLevel.value,
      form.voucherTrigger.value,
      form.scheduleDate && form.scheduleTime
        ? dayjs(
            `${form.scheduleDate} ${form.scheduleTime}`,
            'YYYY-MM-DD HH:mm:ss'
          ).format()
        : undefined,
      form.membershipLevelUp.value,
      form.limitPerDay,
      form.triggerInvoiceType.join(','),
      form.voucherPurposeParent.value,
      form.voucherPurposeChild.value,
      form.pointAmount,
      form.pointExpiry,
      form.pointPurpose.join(','),
      form.discountAmount,
      form.discountMinimum,
      form.discountPercentage,
      form.discountPercentageMinimum,
      form.discountPercentageMaximum,
      form.cashbackPercentage,
      form.cashbackPercentageMinimum,
      form.cashbackPercentageMaximum,
      form.voucherPurposeInvoiceType.join(','),
      form.limitUsage,
      dayjs(
        `${form.startDate} ${form.startTime}`,
        'YYYY-MM-DD HH:mm:ss'
      ).format(),
      dayjs(`${form.endDate} ${form.endTime}`, 'YYYY-MM-DD HH:mm:ss').format(),
      adminEmail,
      form.budgetAmount === undefined ? null : form.budgetAmount,
      form.voucherPurposeProductType.join(','),
      form.voucherExpiryDate
    )

    if (form.voucherTarget.value === 'SELECTED_USERS') {
      this.presenter
        .uploadFile(new UploadProgrammaticVoucherRequest(form.selectedUserFile))
        .then(data => {
          payload.selectedUserUrl = <string>data.url
          payload.selectedUser = <string>data.selectedUser
          payload.selectedUserFileName = <string>data.fileName

          this.onCreateProgramVoucher(payload)
        })
        .catch(error => {
          this.setLoading(false)
          Vue.notify({
            title: 'Upload User File Failed',
            text:
              error.status === 400 || error.status === 422
                ? error.error.message.en
                : 'Something wrong',
            type: 'error',
            duration: 9000,
          })
        })
        .finally(() => {
          this.setUploading(false)
        })
    } else {
      this.onCreateProgramVoucher(payload)
    }
  }

  @Action({ rawError: true })
  onCreateProgramVoucher(payload: CreateProgrammaticVoucherRequest): void {
    this.setSuccessCreateVoucher(false)

    this.presenter
      .create(payload)
      .then(() => {
        EventBus.$emit(EventBusConstants.CREATE_VOUCHER_SUCCESS, {})
        this.setSuccessCreateVoucher(true)
      })
      .catch(error => {
        Vue.notify({
          title: 'Create Programmatic Voucher Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public updateProgrammaticVoucher(form: FormProgrammaticVoucherEdit): void {
    this.setLoading(true)
    this.setSuccessUpdateVoucher(false)
    const adminEmail = LocalStorage.getLocalStorage(
      LocalStorage.LOGIN_IDENTITY,
      true
    )

    const payload = new UpdateProgrammaticVoucherRequest(
      form.voucherTarget.value,
      form.selectedUser,
      form.selectedUserFileUrl,
      form.selectedUserFileName,
      form.os.value,
      form.osValidation.value,
      form.osValidationVersion,
      form.routeIds.map(item => Number(item.value)),
      form.membershipLevel.value,
      form.voucherTrigger.value,
      form.scheduleDate && form.scheduleTime
        ? dayjs(
            `${form.scheduleDate} ${form.scheduleTime}`,
            'YYYY-MM-DD HH:mm:ss'
          ).format()
        : undefined,
      form.membershipLevelUp.value,
      form.limitPerDay,
      form.triggerInvoiceType.join(','),
      form.voucherPurposeParent.value,
      form.voucherPurposeChild.value,
      form.pointAmount,
      form.pointExpiry,
      form.pointPurpose.join(','),
      form.discountAmount,
      form.discountMinimum,
      form.discountPercentage,
      form.discountPercentageMinimum,
      form.discountPercentageMaximum,
      form.cashbackPercentage,
      form.cashbackPercentageMinimum,
      form.cashbackPercentageMaximum,
      form.voucherPurposeInvoiceType.join(','),
      form.limitUsage,
      dayjs(
        `${form.startDate} ${form.startTime}`,
        'YYYY-MM-DD HH:mm:ss'
      ).format(),
      dayjs(`${form.endDate} ${form.endTime}`, 'YYYY-MM-DD HH:mm:ss').format(),
      adminEmail,
      form.budgetAmount === undefined ? null : form.budgetAmount,
      form.voucherPurposeProductType.join(', '),
      form.voucherExpiryDate
    )

    this.presenter
      .update(form.voucherId, payload)
      .then(() => {
        this.setSuccessUpdateVoucher(true)
      })
      .catch(error => {
        Vue.notify({
          title: 'Update Programmatic Voucher Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public deleteVoucher(form: { voucherId: string }): void {
    this.setLoading(true)
    this.setSuccessCancelVoucher(false)

    const payload = new DeleteProgrammaticVoucherRequest(
      LocalStorage.getLocalStorage(LocalStorage.LOGIN_IDENTITY, true)
    )

    this.presenter
      .delete(form.voucherId, payload)
      .then(() => {
        this.setSuccessCancelVoucher(true)
      })
      .catch(error => {
        this.setSuccessCancelVoucher(false)
        Vue.notify({
          title: 'Delete Programmatic Voucher Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getVoucherDistributions(form: {
    id: string
    params: IParameter
  }): void {
    this.setLoading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(form.params),
      'snake_case'
    )

    this.presenter
      .getVoucherDistributions(form.id, formattedParams)
      .then(res => {
        this.setVoucherDistribution(res)
      })
      .catch(error => {
        this.setVoucherDistribution(
          new VoucherDistributions(this.paginationDistribution, [])
        )
        Vue.notify({
          title: 'Fetch Voucher Distribution Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getVoucherHistory(form: { id: string; params: IParameter }): void {
    this.setLoading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(form.params),
      'snake_case'
    )

    this.presenter
      .getHistories(form.id, formattedParams)
      .then(res => {
        this.setVoucherHistory(res)
      })
      .catch(error => {
        this.setVoucherHistory(
          new ProgrammaticVoucherHistories(this.paginationHistory, [
            new ProgrammaticVoucherHistory(),
          ])
        )
        Vue.notify({
          title: 'Fetch History Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getVoucherHistoryV2(form: { id: string; params: IParameter }): void {
    this.setLoading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(form.params),
      'snake_case'
    )

    this.presenter
      .getHistoriesV2(form.id, formattedParams)
      .then(res => {
        this.setVoucherHistory(res)
      })
      .catch(error => {
        this.setVoucherHistory(
          new ProgrammaticVoucherHistories(this.paginationHistory, [
            new ProgrammaticVoucherHistory(),
          ])
        )
        Vue.notify({
          title: 'Fetch History Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getExportedFile(form: { id: string; params: IParameter }): void {
    this.setDownloading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(form.params),
      'snake_case'
    )

    this.presenter
      .exportHistories(form.id, formattedParams)
      .then(res => {
        window.open(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Download Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setDownloading(false)
      })
  }

  @Action({ rawError: true })
  public getExportedFileV2(form: { id: string; params: IParameter }): void {
    this.setDownloading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(form.params),
      'snake_case'
    )

    this.presenter
      .exportHistoriesV2(form.id, formattedParams)
      .then(res => {
        window.open(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Download Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setDownloading(false)
      })
  }

  @Action({ rawError: true })
  public getTriggers(): void {
    this.setTriggerLoading(true)

    this.presenter
      .getTriggers()
      .then(res => {
        this.setTriggerData(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Trigger List Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setTriggerLoading(false)
      })
  }

  @Mutation
  private setVoucherDistribution(distributions: VoucherDistributions): void {
    this.paginationDistribution = distributions.pagination as Pagination
    this.distributionData = distributions.data as VoucherDistribution[]
  }

  @Mutation
  private setVoucherHistory(histories: ProgrammaticVoucherHistories): void {
    this.paginationHistory = histories.pagination as Pagination
    this.historyData = histories.data as ProgrammaticVoucherHistory[]
  }

  @Mutation
  public resetVoucherHistory(): void {
    this.paginationHistory = new Pagination()
    this.historyData = []
  }

  @Mutation
  private setTriggerData(triggers: Trigger[]): void {
    this.triggerData = triggers.map(item => ({
      label: item.triggerName,
      value: item.triggerId,
    }))
  }

  @Mutation
  private setProgrammaticVoucherData(
    publicVouchers: ProgrammaticVouchers
  ): void {
    this.paginationData = publicVouchers.pagination as Pagination
    this.voucherData = publicVouchers.data as ProgrammaticVoucher[]
  }

  @Mutation
  private setProgrammaticVoucherDetail(
    publicVoucher: ProgrammaticVoucher
  ): void {
    this.voucherDetail = publicVoucher
  }

  @Mutation
  private setDownloading(bool: boolean): void {
    this.isDownloading = bool
  }

  @Mutation
  private setUploading(bool: boolean): void {
    this.isUploading = bool
  }

  @Mutation
  private setLoading(bool: boolean): void {
    this.isLoading = bool
  }

  @Mutation
  private setRouteLoading(bool: boolean): void {
    this.isLoadingRoute = bool
  }

  @Mutation
  private setTriggerLoading(bool: boolean): void {
    this.isLoadingTrigger = bool
  }

  @Mutation
  private setSuccessCancelVoucher(bool: boolean): void {
    this.isSuccessCancelVoucher = bool
  }

  @Mutation
  private setSuccessCreateVoucher(bool: boolean): void {
    this.isSuccessCreate = bool
  }

  @Mutation
  private setSuccessUpdateVoucher(bool: boolean): void {
    this.isSuccessUpdate = bool
  }
}

export default getModule(ProgrammaticVoucherController)
