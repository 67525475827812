import {
  CreateProgrammaticVoucherRequestInterface,
  UpdateProgrammaticVoucherRequestInterface,
  DeleteProgrammaticVoucherRequestInterface,
  UploadProgrammaticVoucherRequestInterface,
} from '@/data/payload/contracts/ProgrammaticVoucherRequest'

export class CreateProgrammaticVoucherRequest
  implements CreateProgrammaticVoucherRequestInterface {
  public voucherName?: string
  public voucherTarget?: string
  public selectedUser?: string
  public selectedUserUrl?: string
  public selectedUserFileName?: string
  public os?: string
  public osValidation?: string
  public osValidationVersion?: string
  public routeIds?: string[]
  public membershipLevel?: string
  public voucherTrigger?: string
  public scheduledAt?: string
  public membershipLevelUp?: string
  public limitPerDay?: number
  public triggerInvoiceType?: string
  public voucherPurposeParent?: string
  public voucherPurposeChild?: string
  public pointAmount?: number
  public pointExpiry?: number
  public pointPurpose?: string
  public discountAmount?: number
  public discountMinimum?: number
  public discountPercentage?: number
  public discountPercentageMinimum?: number
  public discountPercentageMaximum?: number
  public cashbackPercentage?: number
  public cashbackPercentageMinimum?: number
  public cashbackPercentageMaximum?: number
  public voucherPurposeInvoiceType?: string
  public limitUsage?: number
  public startDate?: string
  public endDate?: string
  public actorName?: string
  public budgetAmount?: null | number
  public voucherPurposeProductType?: string
  public voucherExpirationDate?: string

  constructor(
    voucherName?: string,
    voucherTarget?: string,
    selectedUser?: string,
    selectedUserUrl?: string,
    selectedUserFileName?: string,
    os?: string,
    osValidation?: string,
    osValidationVersion?: string,
    routeIds?: string[],
    membershipLevel?: string,
    voucherTrigger?: string,
    scheduledAt?: string,
    membershipLevelUp?: string,
    limitPerDay?: number,
    triggerInvoiceType?: string,
    voucherPurposeParent?: string,
    voucherPurposeChild?: string,
    pointAmount?: number,
    pointExpiry?: number,
    pointPurpose?: string,
    discountAmount?: number,
    discountMinimum?: number,
    discountPercentage?: number,
    discountPercentageMinimum?: number,
    discountPercentageMaximum?: number,
    cashbackPercentage?: number,
    cashbackPercentageMinimum?: number,
    cashbackPercentageMaximum?: number,
    voucherPurposeInvoiceType?: string,
    limitUsage?: number,
    startDate?: string,
    endDate?: string,
    actorName?: string,
    budgetAmount?: null | number,
    voucherPurposeProductType?: string,
    voucherExpirationDate?: string
  ) {
    this.voucherName = voucherName
    this.voucherTarget = voucherTarget
    this.selectedUser = selectedUser
    this.selectedUserUrl = selectedUserUrl
    this.selectedUserFileName = selectedUserFileName
    this.os = os
    this.osValidation = osValidation
    this.osValidationVersion = osValidationVersion
    this.routeIds = routeIds
    this.membershipLevel = membershipLevel
    this.voucherTrigger = voucherTrigger
    this.scheduledAt = scheduledAt
    this.membershipLevelUp = membershipLevelUp
    this.limitPerDay = limitPerDay
    this.triggerInvoiceType = triggerInvoiceType
    this.voucherPurposeParent = voucherPurposeParent
    this.voucherPurposeChild = voucherPurposeChild
    this.pointAmount = pointAmount
    this.pointExpiry = pointExpiry
    this.pointPurpose = pointPurpose
    this.discountAmount = discountAmount
    this.discountMinimum = discountMinimum
    this.discountPercentage = discountPercentage
    this.discountPercentageMinimum = discountPercentageMinimum
    this.discountPercentageMaximum = discountPercentageMaximum
    this.cashbackPercentage = cashbackPercentage
    this.cashbackPercentageMinimum = cashbackPercentageMinimum
    this.cashbackPercentageMaximum = cashbackPercentageMaximum
    this.voucherPurposeInvoiceType = voucherPurposeInvoiceType
    this.limitUsage = limitUsage
    this.startDate = startDate
    this.endDate = endDate
    this.actorName = actorName
    this.budgetAmount = budgetAmount
    this.voucherPurposeProductType = voucherPurposeProductType
    this.voucherExpirationDate = voucherExpirationDate
  }

  public toPayload() {
    const errValidDate = 'Invalid Date'
    if (this.startDate === errValidDate) {
      this.startDate = undefined
    }
    if (this.endDate === errValidDate) {
      this.endDate = undefined
    }
    if (this.scheduledAt === errValidDate) {
      this.scheduledAt = undefined
    }

    const data = {
      programmatic_voucher_name: this.voucherName,
      programmatic_voucher_target: this.voucherTarget,
      target_data: {
        selected_user: this.selectedUser,
        selected_user_file_url: this.selectedUserUrl,
        selected_user_file_name: this.selectedUserFileName,
        os: this.os,
        version_validation: this.osValidation,
        version_value: this.osValidationVersion,
        route_id: this.routeIds,
        membership_level: this.membershipLevel,
      },
      programmatic_voucher_trigger: this.voucherTrigger,
      trigger_data: {
        scheduled_at: this.scheduledAt,
        membership_level_up: this.membershipLevelUp,
        limit_per_day: this.limitPerDay,
        invoice_type: this.triggerInvoiceType,
      },
      programmatic_voucher_purpose_parent: this.voucherPurposeParent,
      programmatic_voucher_purpose: this.voucherPurposeChild,
      voucher_purpose_data: {
        point_amount: this.pointAmount,
        point_expiry_day: this.pointExpiry,
        point_purpose: this.pointPurpose,
        discount_amount: this.discountAmount,
        discount_amount_minimum_transaction: this.discountMinimum,
        discount_percentage: this.discountPercentage,
        discount_percentage_minimum_transaction: this.discountPercentageMinimum,
        discount_percentage_maximum_amount: this.discountPercentageMaximum,
        cashback_percentage: this.cashbackPercentage,
        cashback_percentage_minimum_transaction: this.cashbackPercentageMinimum,
        cashback_percentage_maximum_point_amount: this
          .cashbackPercentageMaximum,
        invoice_type: this.voucherPurposeInvoiceType,
        product_type: this.voucherPurposeProductType,
      },
      voucher_limit_usage: this.limitUsage,
      voucher_expiry_date: this.voucherExpirationDate + 'T23:59:59+07:00',
      start_date: this.startDate,
      end_date: this.endDate,
      actor_name: this.actorName,
      budget_amount: this.budgetAmount,
    }

    return JSON.stringify({
      ...data,
    })
  }
}

export class UpdateProgrammaticVoucherRequest
  implements UpdateProgrammaticVoucherRequestInterface {
  public voucherTarget?: string
  public selectedUser?: string
  public selectedUserUrl?: string
  public selectedUserFileName?: string
  public os?: string
  public osValidation?: string
  public osValidationVersion?: string
  public routeIds?: string[] | number[]
  public membershipLevel?: string
  public voucherTrigger?: string
  public scheduledAt?: string
  public membershipLevelUp?: string
  public limitPerDay?: number
  public triggerInvoiceType?: string
  public voucherPurposeParent?: string
  public voucherPurposeChild?: string
  public pointAmount?: number
  public pointExpiry?: number
  public pointPurpose?: string
  public discountAmount?: number
  public discountMinimum?: number
  public discountPercentage?: number
  public discountPercentageMinimum?: number
  public discountPercentageMaximum?: number
  public cashbackPercentage?: number
  public cashbackPercentageMinimum?: number
  public cashbackPercentageMaximum?: number
  public voucherPurposeInvoiceType?: string
  public limitUsage?: number
  public startDate?: string
  public endDate?: string
  public actorName?: string
  public budgetAmount?: null | number
  public voucherPurposeProductType?: string
  public voucherExpirationDate?: string

  constructor(
    voucherTarget?: string,
    selectedUser?: string,
    selectedUserUrl?: string,
    selectedUserFileName?: string,
    os?: string,
    osValidation?: string,
    osValidationVersion?: string,
    routeIds?: string[] | number[],
    membershipLevel?: string,
    voucherTrigger?: string,
    scheduledAt?: string,
    membershipLevelUp?: string,
    limitPerDay?: number,
    triggerInvoiceType?: string,
    voucherPurposeParent?: string,
    voucherPurposeChild?: string,
    pointAmount?: number,
    pointExpiry?: number,
    pointPurpose?: string,
    discountAmount?: number,
    discountMinimum?: number,
    discountPercentage?: number,
    discountPercentageMinimum?: number,
    discountPercentageMaximum?: number,
    cashbackPercentage?: number,
    cashbackPercentageMinimum?: number,
    cashbackPercentageMaximum?: number,
    voucherPurposeInvoiceType?: string,
    limitUsage?: number,
    startDate?: string,
    endDate?: string,
    actorName?: string,
    budgetAmount?: null | number,
    voucherPurposeProductType?: string,
    voucherExpirationDate?: string
  ) {
    this.voucherTarget = voucherTarget
    this.selectedUser = selectedUser
    this.selectedUserUrl = selectedUserUrl
    this.selectedUserFileName = selectedUserFileName
    this.os = os
    this.osValidation = osValidation
    this.osValidationVersion = osValidationVersion
    this.routeIds = routeIds
    this.membershipLevel = membershipLevel
    this.voucherTrigger = voucherTrigger
    this.scheduledAt = scheduledAt
    this.membershipLevelUp = membershipLevelUp
    this.limitPerDay = limitPerDay
    this.triggerInvoiceType = triggerInvoiceType
    this.voucherPurposeParent = voucherPurposeParent
    this.voucherPurposeChild = voucherPurposeChild
    this.pointAmount = pointAmount
    this.pointExpiry = pointExpiry
    this.pointPurpose = pointPurpose
    this.discountAmount = discountAmount
    this.discountMinimum = discountMinimum
    this.discountPercentage = discountPercentage
    this.discountPercentageMinimum = discountPercentageMinimum
    this.discountPercentageMaximum = discountPercentageMaximum
    this.cashbackPercentage = cashbackPercentage
    this.cashbackPercentageMinimum = cashbackPercentageMinimum
    this.cashbackPercentageMaximum = cashbackPercentageMaximum
    this.voucherPurposeInvoiceType = voucherPurposeInvoiceType
    this.limitUsage = limitUsage
    this.startDate = startDate
    this.endDate = endDate
    this.actorName = actorName
    this.budgetAmount = budgetAmount
    this.voucherPurposeProductType = voucherPurposeProductType
    this.voucherExpirationDate = voucherExpirationDate
  }

  public toPayload() {
    const errValidDate = 'Invalid Date'
    if (this.startDate === errValidDate) {
      this.startDate = undefined
    }
    if (this.endDate === errValidDate) {
      this.endDate = undefined
    }

    const data = {
      programmatic_voucher_target: this.voucherTarget,
      target_data: {
        selected_user: this.selectedUser,
        selected_user_file_url: this.selectedUserUrl,
        selected_user_file_name: this.selectedUserFileName,
        os: this.os,
        version_validation: this.osValidation,
        version_value: this.osValidationVersion,
        route_id: this.routeIds,
        membership_level: this.membershipLevel,
      },
      programmatic_voucher_trigger: this.voucherTrigger,
      trigger_data: {
        scheduled_at: this.scheduledAt,
        membership_level_up: this.membershipLevelUp,
        limit_per_day: this.limitPerDay,
        invoice_type: this.triggerInvoiceType,
      },
      programmatic_voucher_purpose_parent: this.voucherPurposeParent,
      programmatic_voucher_purpose: this.voucherPurposeChild,
      voucher_purpose_data: {
        point_amount: this.pointAmount,
        point_expiry_day: this.pointExpiry,
        point_purpose: this.pointPurpose,
        discount_amount: this.discountAmount,
        discount_amount_minimum_transaction: this.discountMinimum,
        discount_percentage: this.discountPercentage,
        discount_percentage_minimum_transaction: this.discountPercentageMinimum,
        discount_percentage_maximum_amount: this.discountPercentageMaximum,
        cashback_percentage: this.cashbackPercentage,
        cashback_percentage_minimum_transaction: this.cashbackPercentageMinimum,
        cashback_percentage_maximum_point_amount: this
          .cashbackPercentageMaximum,
        invoice_type: this.voucherPurposeInvoiceType,
        product_type: this.voucherPurposeProductType,
      },
      voucher_limit_usage: this.limitUsage,
      start_date: this.startDate,
      end_date: this.endDate,
      actor_name: this.actorName,
      budget_amount: this.budgetAmount,
      voucher_expiry_date: this.voucherExpirationDate?.includes('Z')
        ? this.voucherExpirationDate
        : this.voucherExpirationDate + 'T23:59:59+07:00',
    }

    return JSON.stringify({
      ...data,
    })
  }
}

export class DeleteProgrammaticVoucherRequest
  implements DeleteProgrammaticVoucherRequestInterface {
  public actorName?: string

  constructor(actorName?: string) {
    this.actorName = actorName
  }

  public toPayload() {
    const data = {
      actor_name: this.actorName,
    }

    return JSON.stringify({
      ...data,
    })
  }
}

export class UploadProgrammaticVoucherRequest
  implements UploadProgrammaticVoucherRequestInterface {
  public userFile?: Blob

  constructor(userFile?: Blob) {
    this.userFile = userFile
  }

  public toPayload() {
    // Specific for form data, this toPayload() will actually convert them into FormData
    const data = new FormData()
    data.append('selected_user_file', this.userFile as Blob)

    return data
  }
}
